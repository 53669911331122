<template>
  <div>
    <general-table
      ref="friendsTable"
      :api-url="APIURL"
      :add-type="addType"
      :view-content="false"
      :add-component-name="addComponentName"
      :edit-component="editComponent"
      :type="type"
      :columns="columns"
      :guard="guard"
    />
  </div>
</template>

<script>
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  data() {
    return {
      APIURL: 'roles',
      addType: 'Add Role',
      addComponentName: 'add-role',
      editComponent: 'edit-role',
      viewContent: true,
      type: 'page',
      guard: 'roles',
      columns: [
        { key: 'id' },
        { key: 'name', label: 'Name' },
        // { key: 'phone_number', label: 'phone Number' },
        { key: 'actions' },
      ],
    }
  },
}
</script>

<style>

</style>
